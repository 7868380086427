/**
 * Enforces last-write wins consistency on the given consistent object
 *
 * @param local The local version of the object
 * @param remote The remote version of the object
 *
 * @returns The merged value, and a list of values that changed from local
 */
export function mergeLWW(options) {
    if (!options.local && options.remote) {
        const changed = Object.keys(options.remote).filter((key) => key !== "consistency" && !options.ignore.includes(key));
        return { resolved: options.remote, changed, rejected: [] };
    }
    if (!options.remote && options.local) {
        const rejected = Object.keys(options.local).filter((key) => key !== "consistency" && !options.ignore.includes(key));
        return { resolved: options.local, changed: [], rejected };
    }
    if (!options.remote && !options.local) {
        return { resolved: options.local, changed: [], rejected: [] };
    }
    const local = options.local;
    const remote = options.remote;
    const resolved = { ...local, consistency: { ...local.consistency } };
    const changed = [];
    const rejected = [];
    for (const key of Object.keys(remote)) {
        if (options.ignore.includes(key) || key === "consistency") {
            continue;
        }
        const localHistory = local.consistency[key];
        const remoteHistory = remote.consistency[key];
        // Local History is more advanced
        const shouldReject = localHistory.count > remoteHistory.count;
        if (shouldReject) {
            rejected.push(key);
        }
        const shouldOverride = 
        // Remote Count > Local Count
        remoteHistory.count > localHistory.count ||
            // Tie Breaker
            (remoteHistory.count === localHistory.count &&
                remoteHistory.peer > localHistory.peer);
        if (shouldOverride) {
            resolved[key] = remote[key];
            resolved.consistency[key] = {
                count: remote.consistency[key].count,
                peer: remote.consistency[key].peer,
                history: remote.consistency[key].history,
                instant: remote.consistency[key].instant,
            };
            changed.push(key);
        }
    }
    return { resolved, changed, rejected };
}
export function initLWW({ value, peer, ignore, }) {
    const keys = Object.keys(value).filter((key) => !ignore.includes(key));
    const consistency = Object.fromEntries(keys.map((key) => [
        key,
        { count: 0, instant: new Date().toISOString(), peer, history: [] },
    ]));
    return { ...value, consistency };
}
export function equivalentLWW({ left, right, ignore, }) {
    if (!left || !right) {
        return !left && !right;
    }
    for (const key of Object.keys(left)) {
        if (ignore.includes(key) || key === "consistency") {
            continue;
        }
        const leftConsistency = left.consistency[key];
        const rightConsistency = right.consistency[key];
        if (leftConsistency.count !== rightConsistency.count ||
            leftConsistency.peer !== rightConsistency.peer) {
            return false;
        }
    }
    return true;
}
export function updateLWW(object, { key, value, peer, instant = new Date().toISOString() }) {
    const current = object.consistency[key].history;
    const register = {
        count: object.consistency[key].count + 1,
        peer,
        instant,
        history: current.concat({
            prev: object[key],
            peer,
            instant: object.consistency[key].instant,
        }),
    };
    return {
        ...object,
        [key]: value,
        consistency: { ...object.consistency, [key]: register },
    };
}
export function isKeyLWW(key, ignore) {
    return !ignore.includes(key) && key !== "consistency";
}
