export function mergeGrowSet({ local, remote, }) {
    const localSet = new Set(local);
    const remoteSet = new Set(remote);
    const resolved = localSet.union(remoteSet);
    const localOnly = localSet.difference(remoteSet);
    const remoteOnly = remoteSet.difference(localSet);
    return {
        resolved: [...resolved],
        localOnly: [...localOnly],
        remoteOnly: [...remoteOnly],
    };
}
